import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import image1 from "../assets/st calpe.jpg";
import image2 from "../assets/st calpe 2.jpg";
import image3 from "../assets/st calpe 3.jpg";
import image4 from "../assets/st calpe 4.jpeg";
import image5 from "../assets/st calpe 5.jpg";
import image7 from "../assets/st calpe 7.jpg";
import image from "../assets/graph.png";  // Energy consumption graph

const Project = () => {
  const { t } = useTranslation(); // Use the translation hook

  const [currentImage, setCurrentImage] = useState(0);
  const images = [image1, image2, image3, image4, image5, image7];

  const nextImage = () => {
    setCurrentImage((prev) => (prev + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-gradient-to-b from-white to-[#f0f4f2] py-10 px-5 md:px-20">
        {/* Heading Section */}
        <div className="text-center mb-14">
          <h1
            style={{
              color: "#5b8251",
              textAlign: "center",
              lineHeight: "1.5",
              fontSize: "2rem",
              fontWeight: "bold",
            }}
          >
            {t('project.title')}
            <span className="block relative w-32 h-2 bg-[#5b8251] left-1/2 transform -translate-x-1/2 -translate-y-1 rounded-md"></span>
          </h1>
        </div>

        {/* Main Content Container */}
        <div className="bg-white shadow md:mx-10 lg:mx-20 xl:mx-32 rounded-md py-8 my-5 px-5 md:px-10">
          <div className="text-center mb-10 md:mb-14">
            <h2 className="text-[#5b8251] text-3xl md:text-5xl font-bold">
              {t('project.projectTitle')}
            </h2>
          </div>

          {/* Project Description */}
          <div className="max-w-5xl mx-auto text-lg md:text-2xl leading-relaxed text-gray-800 space-y-8">
            <p className="font-bold text-xl md:text-3xl text-[#5b8251]">
              {t('project.description1')}
            </p>
            <p>{t('project.description2')}</p>
          </div>

          {/* Energy Consumption Graph Section */}
          <div className="max-w-5xl mx-auto text-lg md:text-2xl leading-relaxed text-gray-800 space-y-8 mt-12">
            <h2 className="text-2xl md:text-3xl font-bold text-[#5b8251]">
              {t('project.energyGraph')}
            </h2>
            <div className="text-center mt-8">
              <img
                src={image}
                alt="Energy Consumption Graph"
                className="w-full md:w-full lg:w-full xl:w-6/6 2xl:w-7/6 mx-auto object-contain"
              />
            </div>
          </div>

          {/* Installation Overview Section */}
          <div className="max-w-5xl mx-auto text-lg md:text-2xl leading-relaxed text-gray-800 space-y-8 mt-12">
            <h2 className="text-2xl md:text-3xl font-bold text-[#5b8251]">
              {t('project.installationOverview')}
            </h2>

            {/* Image Carousel with List Layout */}
            <div className="relative flex items-center justify-center mt-6 md:mt-12">
              <button
                onClick={prevImage}
                className="absolute left-2 bg-[#5b8251] text-white p-3 rounded-full hover:bg-[#4a6d41] transition"
              >
                &#8592;
              </button>

              <div className="flex overflow-hidden max-w-full space-x-4">
                {images.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    alt={`Installation Image ${index + 1}`}
                    className={`transition-transform duration-300 object-cover rounded-lg ${
                      currentImage === index
                        ? "block w-64 h-64 md:w-80 md:h-80 opacity-100"
                        : "hidden"
                    }`}
                    style={{
                      height: "350px",
                      width: "100%",
                      objectFit: "cover",
                      display: currentImage === index ? "block" : "none",
                    }}
                  />
                ))}
              </div>

              <button
                onClick={nextImage}
                className="absolute right-2 bg-[#5b8251] text-white p-3 rounded-full hover:bg-[#4a6d41] transition"
              >
                &#8594;
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Project;
