import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const ContactButton = () => {
  const [isHovered, setIsHovered] = useState(false); // State to track hover status
  const { t } = useTranslation(); // Initialize the translation hook

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Smoothly scroll to the top
  };

  return (
    <div className="navbar-button">
      <Link to="/form">
        <button
          onClick={scrollToTop} // Attach click event
          onMouseEnter={() => setIsHovered(true)} // Trigger hover on mouse enter
          onMouseLeave={() => setIsHovered(false)} // Reset hover on mouse leave
          style={{
            backgroundColor: '#5b8251',
            position: 'fixed',
            padding: '0.75rem 0.7rem',
            bottom: '1rem',
            right: '0rem',
            borderRadius: '20px',
            border: 'none',
            zIndex: 10000,
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            opacity: isHovered ? 1 : 0.8, // Change opacity on hover
            transform: isHovered ? 'translateY(-5px)' : 'none', // Lift button on hover
            transition: 'transform 0.3s ease, opacity 0.3s ease', // Smooth transition
          }}
        >
          {t('contactButtonText')} {/* Translate button text dynamically */}
        </button>
      </Link>
    </div>
  );
};

export default ContactButton;
