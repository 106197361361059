import React from 'react';
import { Link } from 'react-router-dom';
import {
  MapIcon,
  PhoneIcon,
  EnvelopeIcon,
} from '@heroicons/react/24/solid';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'; // React icons import
import logo from '../assets/ecologo.png';
import { useTranslation } from 'react-i18next'; // Importing the translation hook

const Footer = () => {
  const { t } = useTranslation(); // Use translation hook

  // Function to scroll to top of the page
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <section className='bg-[#5b8251] flex flex-col pt-4 pb-2 md:pb-0'>
      <div className='flex flex-col md:flex-row justify-around md:items-start items-center py-6 space-y-4 md:space-y-0'>

        {/* Logo and description aligned in a row */}
        <div className='flex items-center md:ml-4 text-white'>
          <img src={logo} alt="ECO Green Projects Logo" className='h-20 w-20 md:ml-4 mx-4' /> 
          <div className="ml-0">
            <p className="text-sm mt-4 px-4 text-left leading-relaxed max-w-[300px]">
              {t('footer.description')}
            </p>
          </div>
        </div>

        {/* Our Services */}
        <div className='flex flex-col items-baseline text-white'>
          <h2 className='font-bold text-lg mb-2'>{t('footer.our_services')}</h2>
          <div className='flex flex-col items-baseline text-white text-sm space-y-2'>
            <Link to='/services' onClick={scrollToTop} className='hover:text-[#6fb962] transition-colors duration-300'>{t('footer.solar_panel_installation')}</Link>
            <Link to='/services' onClick={scrollToTop} className='hover:text-[#6fb962] transition-colors duration-300'>{t('footer.air_conditioning_installation')}</Link>
            <Link to='/services' onClick={scrollToTop} className='hover:text-[#6fb962] transition-colors duration-300'>{t('footer.pool_heat_pump_installation')}</Link>
            <Link to='/services' onClick={scrollToTop} className='hover:text-[#6fb962] transition-colors duration-300'>{t('footer.electric_car_charging_station_installation')}</Link>
          </div>
        </div>

        {/* Contact Information */}
        <div className='flex flex-col space-y-2 items-baseline text-white'>
          <h2 className='font-bold text-lg mb-2'>{t('footer.contact')}</h2>
          <address className='flex flex-col space-y-3'>
            <span className='text-sm flex items-center'>
              <EnvelopeIcon className='text-[#6fb962] h-5 w-5 mx-1' />
              <a href='mailto:info@ecogreenprojects.net' className='hover:text-[#6fb962] transition-colors duration-300'>
                info@ecogreenprojects.net
              </a>
            </span>
            <span className='text-sm flex items-center'>
              <MapIcon className='text-[#6fb962] h-5 w-5 mx-1' />
              <a
                href="https://www.google.com/maps?q=Partida+Les+Cucarres+03710+CALPE"
                target="_blank"
                rel="noopener noreferrer"
                className='hover:text-[#6fb962] transition-colors duration-300'
              >
                Partida Les Cucarres 03710 CALPE
              </a>
            </span>
            <span className='text-sm flex items-center'>
              <PhoneIcon className='text-[#6fb962] h-5 w-5 mx-1' />
              <a href="tel:+3469092639" className='hover:text-[#6fb962] transition-colors duration-300'>
                +34 6 90 92 63 9
              </a>
            </span>
          </address>
        </div>

        {/* Our Presence */}
        <div className='flex flex-col items-baseline text-white'>
          <h2 className='font-bold text-lg mb-2'>{t('footer.our_presence')}</h2>
          <div className='flex flex-col items-baseline text-white text-sm space-y-1'>
            <Link to='/projects' onClick={scrollToTop} className='hover:text-[#6fb962] transition-colors duration-300'>{t('footer.solar_installation_in_calpe')}</Link>
          </div>
        </div>
      </div>

      {/* Social Media Links */}
      <div className='flex items-center justify-between p-4 connect px-6 bg-[#4e6f43]'>
        <h2 className='font-bold text-white text-lg'>{t('footer.follow_us')}</h2>
        <div className='flex space-x-4 flex-grow justify-center'>
          <a href='https://www.facebook.com' target='_blank' rel='noopener noreferrer' className='hover:scale-110 transition-transform duration-300'>
            <FaFacebook className='text-white h-6 w-6 hover:text-[#6fb962] transition-colors duration-300' />
          </a>
          <a href='https://www.instagram.com' target='_blank' rel='noopener noreferrer' className='hover:scale-110 transition-transform duration-300'>
            <FaInstagram className='text-white h-6 w-6 hover:text-[#6fb962] transition-colors duration-300' />
          </a>
          <a href='https://www.linkedin.com' target='_blank' rel='noopener noreferrer' className='hover:scale-110 transition-transform duration-300'>
            <FaLinkedin className='text-white h-6 w-6 hover:text-[#6fb962] transition-colors duration-300' />
          </a>
        </div>
      </div>

      {/* Copyright Section */}
      <div className='flex flex-col md:flex-row justify-between items-center bg-[#333] text-white text-sm p-4'>
        <span className='mb-2 md:mb-0'>© 2024 Eco Green Projects. {t('footer.terms_conditions')}</span>
        <div className='flex space-x-4'>
          <Link to='/' onClick={scrollToTop} className='hover:text-[#6fb962] transition-colors duration-300'>
            {t('footer.terms_conditions')}
          </Link>
          <Link to='/' onClick={scrollToTop} className='hover:text-[#6fb962] transition-colors duration-300'>
            {t('footer.privacy_policy')}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Footer;
