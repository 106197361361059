import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import React from "react";

const Form = () => {
  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-gradient-to-b from-white to-[#f0f4f2] py-20 px-5 md:px-20">
        {/* Heading Section */}
        <div className="text-center mb-14">
          <h1
            style={{
              color: "#5b8251",
              textAlign: "center",
              lineHeight: "1.5",
              fontSize: "2rem",
              fontWeight: "bold",
            }}
          >
            Form
            <span className="block relative w-32 h-2 bg-[#5b8251] left-1/2 transform -translate-x-1/2 -translate-y-1 rounded-md"></span>
          </h1>
        </div>

        {/* Replacing iframe with the new embedded form */}
        <div className="max-w-4xl mx-auto text-center text-gray-700 px-4 sm:px-6 md:px-10">
          {/* Embedded form using the new div */}
          <iframe
            data-displaylogo="false"
            data-displaylanguageupdate="false"
            data-attr="sp-container-standard"
            id="sp-container-standard"
            src="https://eco-green-projects.spondei.com/s/g5iSNoowYWM="
            height="800"
            width="100%"
            type="inline"
            style={{
              border: "none",
              width: "100%",
              height: "950px", // Ensure the height matches your previous iframe height
              maxWidth: "100%",
              overflow: "hidden",
            }}
          ></iframe>
          <script src="https://app.spondei.com/assets/js/embedCode.js"></script>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Form;
