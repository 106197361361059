import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import ecologo from "../assets/ecologo.png";
import LanguageSwitcher from '../functions/LanguageSwitcher';  
import './Navbar.css';
import { useTranslation } from 'react-i18next';  // Importing the translation hook

const Navbar = () => {
  const { t } = useTranslation(); // Use translation hook
  const [navbarScrolled, setNavbarScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavbarScrolled(true);
      } else {
        setNavbarScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = () => {
    setMenuOpen(false);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <>
      <nav className={`navbar ${navbarScrolled ? 'scrolled' : ''}`}>
        <div className="navbar-logo">
          <NavLink to="/" onClick={handleLinkClick}>
            <img src={ecologo} alt="Logo" />
          </NavLink>
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </div>
        <ul className={`navbar-links ${menuOpen ? 'open' : ''}`}>
          <li>
            <NavLink to="/" activeClassName="active" exact onClick={handleLinkClick}>
              {t('navbar.home')}
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" activeClassName="active" onClick={handleLinkClick}>
              {t('navbar.about')}
            </NavLink>
          </li>
          <li>
            <NavLink to="/services" activeClassName="active" onClick={handleLinkClick}>
              {t('navbar.services')}
            </NavLink>
          </li>
          <li>
            <NavLink to="/projects" activeClassName="active" onClick={handleLinkClick}>
              {t('navbar.projects')}
            </NavLink>
          </li>
          <li>
            <NavLink to="/faqs" activeClassName="active" onClick={handleLinkClick}>
              {t('navbar.faqs')}
            </NavLink>
          </li>
          <li>
            <NavLink to="/form" activeClassName="active" onClick={handleLinkClick}>
              {t('navbar.contact')}
            </NavLink>
          </li>
        </ul>
       
        <div className="navbar-button hide-on-mobile">
          <Link to="/form" onClick={handleLinkClick}>
            <button className="service-button">{t('navbar.ask_for_quote')}</button>
          </Link>
        </div>
        <div className="navbar-right">
          {/* Language Switcher */}
          <LanguageSwitcher onLanguageChange={(lng) => console.log(`Language changed to ${lng}`)} />
        </div>
        
      </nav>
      
      <div className="navbar-spacing"></div>
      
    </>
  );
};

export default Navbar;
