import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import bg2 from "../assets/about.jpg";

const AboutUs = () => {
  const { t } = useTranslation(); // Access the translation function

  return (
    <div className="rounded-md my-4 md:my-10 bg-white flex flex-col md:flex-row justify-between gap-10 mx-4 md:mx-10 p-4 md:p-0">
      {/* Left section: Text */}
      <div className="w-full md:w-1/2 flex flex-col gap-6 p-4 md:p-10">
        <div className="text-left relative">
          <h2 style={{ color: "#5b8251", textAlign: "left", lineHeight: "1.5", fontSize: "2rem", fontWeight: "bold" }}>
            <span className="block relative w-24 h-2 bg-[#5b8251] ml-[22px] rounded-md"></span>
            {t('about.aboutUsTitle')} {/* Translated title */}
          </h2>
        </div>
        <div className="text-left text-black space-y-4 leading-relaxed text-justify">
          <p>{t('about.aboutUsText1')}</p> {/* Translated text */}
          <p>{t('about.aboutUsText2')}</p> {/* Translated text */}
          <p>{t('about.aboutUsText3')}</p> {/* Translated text */}
        </div>

        {/* Button Section */}
        <div className="mt-6">
          <div className="view-all-btn">
            <Link to="/form">
              <button>{t('about.quoteButton')}</button> {/* Translated button text */}
            </Link>
          </div>
        </div>
      </div>

      {/* Right section: Image */}
      <div className="w-full md:w-1/2 flex justify-center p-0">
        <img
          src={bg2}
          alt="Eco Green Projects"
          className="rounded-md w-full h-full object-cover max-h-[700px]"
        />
      </div>
    </div>
  );
};

export default AboutUs;
