export const resources = {
  en: {
    translation: {
      home: {
        ecoGreenProjects: "Eco Green Projects",
        goGreenSaveGreen: "Go Green & Save Green with Solar Energy",
        contactUs: "CONTACT US",
        ourServices: "OUR SERVICES"
      },
      about: {
        aboutUsTitle: "About Us",
        aboutUsText1: "Founded by Sebastien, who fell in love with the breathtaking landscapes of Calp, our mission is to empower locals and expats to embrace sustainable living through solar energy solutions.",
        aboutUsText2: "Imagine reducing your electricity bill by an incredible 85%! Our solar panel installations are not only a smart choice, they are a game changer for your wallet and the environment.",
        aboutUsText3: "For over 15 years, we have been well established in the solar panel industry in Belgium, having successfully completed a number of installations. Today, we bring our expertise to the sunny coasts of Calp, Altea, Moraira, Benissa and beyond.",
        quoteButton: "Ask for a free quote"
      },
      services: {
        ourServicesTitle: "Our Services",
        solarPanelInstallation: "Solar Panel Installation",
        airConditioningInstallation: "Air Conditioning Installation",
        poolHeatPumpInstallation: "Pool Heat Pump Installation",
        heatPumpBoilerInstallation: "Heat Pump Boiler Installation",
        electricCarChargingStation: "Electric Car Charging Station",
        serviceDetailButton: "Our services in detail",
        freeQuoteButton: "Ask for a free quote",
        friendlyPersonalService: "Friendly & Personal Service",
        installationExperience: "Wealth of Experience in Installation & Maintenance",
        available: "Available 24/7"
      },
      ourmission: {
        title: "Our mission",
        paragraph1: "At Eco Green Projects, we are dedicated to transforming the way you think about energy. With a mission centered on saving money through solar energy while promoting eco-friendliness, we strive to make sustainable living accessible to everyone. Our core values of innovation, smart solutions, and sustainability guide us in every project we undertake.",
        paragraph2: "With over 15 years of experience in the solar energy industry and many successful installations, we have established ourselves as a trusted leader in the field. Our passion for renewable energy is rooted in our love for Calp, a location blessed with 300 days of sunshine per year, making it the perfect place for solar panel installations.",
        paragraph3: "We offer a range of services, including solid installation and timely project completion, all designed to help you save money while enjoying the benefits of clean energy. Our customer-focused approach caters to both expats and locals who own homes in South Spain, ensuring that we meet the unique needs of our diverse clientele.",
        viewProjects: "View our projects",
        askQuote: "Ask for a free quote"
      },
      choose: {
        title: "Why Choose Us",
        description: "Choosing us means you will be getting a reliable and experienced professional who has the expertise to deliver quality services.",
        experienced_team: {
          name: "Experienced and Skilled team",
          summary: "Our company team is highly experienced and skilled and well-equipped to handle any issue you may be facing"
        },
        reliable_service: {
          name: "Reliable and Efficient Service",
          summary: "We strive to provide reliable and efficient service to our customers. We arrive on time, complete the work quickly and efficiently"
        },
        competitive_pricing: {
          name: "Competitive Pricing",
          summary: "We offer competitive pricing without compromising on the quality of our work"
        },
        customer_satisfaction: {
          name: "Customer Satisfaction",
          summary: "We listen to your needs and concerns, and we work with you to find the best solution"
        },
        fast_delivery: {
          name: "Fast Delivery",
          summary: "We are quick on delivering quotes and installations"
        }
      },
      navbar: {
        home: "HOME",
        about: "ABOUT US",
        services: "SERVICES",
        projects: "PROJECTS",
        faqs: "FAQS",
        contact: "CONTACT",
        ask_for_quote: "Ask For a Free Quote"
      },
      footer: {
        description: "Eco Green Projects is your partner and expert on solar energy for your project in South of Spain. Our mission is to help you save money thanks to our installation.",
        our_services: "Our Services",
        solar_panel_installation: "Solar Panel Installation",
        air_conditioning_installation: "Air Conditioning Installation Solutions",
        pool_heat_pump_installation: "Pool Heat Pump Installation",
        electric_car_charging_station_installation: "Electric Car Charging Station Installation",
        contact: "Contact",
        follow_us: "Follow Us",
        our_presence: "Our Presence",
        solar_installation_in_calpe: "Solar installation in Calpe",
        terms_conditions: "Terms and Conditions",
        privacy_policy: "Privacy Policy"
      },
      aboutpage: {
        title: "About Us",
        heading: "At Eco Green Project, we are committed to transforming the way you harness energy.",
        founder_info: "Founded by Sebastien, who fell in love with the breathtaking landscapes of Calp, our mission is to empower locals and expats to embrace sustainable living through solar energy solutions.",
        savings: "Imagine reducing your electricity bill by an incredible 85%! Our solar panel installations are not only a smart choice, they are a game changer for your wallet and the environment.",
        experience: "For over 15 years, we have been well established in the solar panel industry in Belgium, having successfully completed a number of installations. Today, we bring our expertise to the sunny coasts of Calp, Altea, Moraira, Benissa, and beyond.",
        services_title: "Our full range of services includes:",
        services: [
          "Solar panel installation: maximize your energy savings with our cutting-edge solar technology.",
          "Air conditioning solutions: keep your home cool and comfortable during the hot summer months.",
          "Pool Heat Pumps: Enjoy your pool all year round with energy-efficient heating solutions.",
          "Thermodynamic Hot Water Tanks: Harness renewable energy for your hot water needs.",
          "Electric Terminals: Ensure your home is equipped with the latest electrical solutions."
        ],
        closing: "Join us in our commitment to creating an eco-friendly environment while saving money. Let Eco Green Project guide you on your journey to sustainable living. Contact us today to find out how we can help you switch to solar energy and enjoy the benefits of a greener lifestyle!"
      },
      result: {
        title: "Our Services",
        services: [
          {
            title: "Solar Panel Installation",
            description: "<span style=display:block;><strong>At Eco Green Projects, we offer professional solar panel installation services to help you harness renewable energy and lower your energy bills.</strong></span></br><span style=display:block;> Our certified technicians provide expert installation, using high-quality solar panels to ensure maximum efficiency and minimal disruption to your daily routine.</span></br><span style=display:block;> Join the green energy revolution and make the switch to sustainable, cost-effective power today.</span>",
            button: "Ask For a Free Quote",
            altText: "Solar Panel Installation"
          },
          {
            title: "Air Conditioning Installation Solutions",
            description: "<strong>At Eco Green Projects, we provide professional air conditioning installation services to ensure optimal comfort for your home or business.</strong></br></br> Our certified HVAC team uses high-quality units and the latest technology to deliver efficient, energy-saving cooling solutions tailored to your needs.</br></br> Upgrade your indoor environment and enjoy a perfectly controlled climate all year round.",
            button: "Ask For a Free Quote",
            altText: "Air Conditioning Installation"
          },
          {
            title: "Pool Heat Pump Installation",
            description: "<strong>At Eco Green Projects, we offer professional pool heat pump installation to help you enjoy a comfortable swimming experience year-round.</strong></br></br> Our energy-efficient heat pumps are tailored to your pool’s size and needs, ensuring optimal performance and cost savings.</br></br> Upgrade your pool with eco-friendly heating solutions that reduce energy costs and extend your swimming season.",
            button: "Ask For a Free Quote",
            altText: "Pool Heat Pump Installation"
          },
          {
            title: "Heat Pump Boiler Installation",
            description: "<strong>At Eco Green Projects, we provide efficient and sustainable heat pump boiler installations for homes and businesses.</strong></br></br> Our certified professionals use state-of-the-art heat pump technology to reduce energy costs and minimize your carbon footprint.</br></br> Experience hassle-free installation and embrace energy-efficient heating that supports a greener future.",
            button: "Ask For a Free Quote",
            altText: "Heat Pump Boiler Installation"
          },
          {
            title: "Electric Car Charging Station Installation",
            description: "<strong>At Eco Green Projects, we specialize in installing electric vehicle (EV) charging stations to support sustainable transportation.</strong></br></br> Our expert team provides efficient, reliable installations with high-quality equipment, ensuring optimal performance and minimal disruption to your daily routine.</br></br> Upgrade to a greener future with our tailored EV charging solutions for home or business.",
            button: "Ask For a Free Quote",
            altText: "Electric Car Charging Station Installation"
          }
        ]
      },
      project: {
        title: "Our Projects",
        projectTitle: "OUR PROJECT IN CALPE",
        description1: "We are pleased to present our inaugural project in Calpe.",
        description2: "This project features a comprehensive solar installation equipped with a battery system. Additionally, we have implemented air conditioning, a heat pump, and an electric vehicle charging station to enhance sustainability and efficiency for the property.",
        energyGraph: "Energy Consumption Graph",
        installationOverview: "Installation Overview"
      },
      faqTitle: "FAQ: Solar Energy & Installation Services in Southern Spain",
  faq1: {
    title: "How much does a solar panel installation cost in southern Spain?",
    content: "The cost of a solar panel installation in southern Spain can vary depending on factors like the size of your home, the system's capacity, and energy needs. For homeowners in areas like Calp, Altea, and Benissa, prices typically range between €5,000 and €12,000 for a full solar energy setup. Expats can benefit from significant long-term savings on their energy bills. To get an accurate price tailored to your home, we offer free, no-obligation quotes. Just contact us, and we’ll provide a detailed breakdown of the costs."
  },
  faq2: {
    title: "What is the ROI (Return on Investment) for solar panels in Spain?",
    content: "The ROI for solar panels in Spain is excellent, especially along the Costa Blanca, where sunny weather is abundant. Most homeowners see a return on investment within 4 to 5 years, depending on the size of the system and energy consumption. For expats in areas like Moraira and Benissa, the savings can be even higher due to reduced reliance on traditional energy sources. Solar energy can reduce your electricity bills by up to 70%, making it a smart long-term investment."
  },
  faq3: {
    title: "Why should I choose your company for solar panel installation?",
    content: "With 20 years of experience in Belgium, we’ve built a reputation for quality and reliability. We bring that same level of expertise to southern Spain, serving areas like Calp, Altea, and beyond. Our key advantages include:<ul><li>Fast responses: We provide quotes quickly, ensuring you don’t have to wait.</li><li>Efficient installation: We complete installations faster than most competitors, ensuring minimal disruption.</li><li>Multilingual support: Our team speaks English, Spanish, French, and Dutch, making it easy for expats to communicate with us.</li></ul>We’re your trusted partner for solar energy solutions in Costa Blanca."
  },
  faq4: {
    title: "How long does it take to install solar panels?",
    content: "We pride ourselves on fast solar panel installations. Typically, the process takes between 1 to 3 days, depending on the size of the system and complexity of the installation. Once you receive a quote and decide to proceed, we can usually schedule the installation within a week for properties in Calp, Altea, Moraira, Benissa, and surrounding areas. Our goal is to make the process as quick and hassle-free as possible."
  },
  faq5: {
    title: "How do I get a quote for a solar panel installation?",
    content: "Getting a free solar panel quote from us is easy. You can:<ul><li>Call or email us directly, and our team will arrange a consultation.</li><li>Fill out the form on our website, and we’ll respond within 24 hours.</li></ul>We offer personalized quotes based on your energy needs and property specifications, ensuring you get the most accurate and competitive pricing available in southern Spain."
  },
  faq6: {
    title: "What types of installations do you offer?",
    content: "We specialize in a variety of energy solutions, including:<ul><li>Solar panel installation: Efficient and cost-effective systems tailored to your home.</li><li>Air conditioning installation: Energy-efficient units that keep your home comfortable year-round.</li><li>Pool heat pump installation: Extend your swimming season with an eco-friendly heating system.</li><li>Heat pump boiler installation: Sustainable heating for your home’s water supply.</li><li>Electric car charging station installation: Convenient home charging solutions for your electric vehicle.</li></ul>Each service is available in Calp, Altea, Moraira, Benissa, and surrounding regions."
  },
  faq7: {
    title: "What areas do you serve in southern Spain?",
    content: "We proudly serve homeowners and expats across the Costa Blanca, including:<ul><li>Calp</li><li>Altea</li><li>Moraira</li><li>Benissa</li></ul>Whether you're looking for solar energy solutions, air conditioning installation, or electric car charging stations, we provide high-quality, reliable services throughout the region."
  },
  contactButtonText: "Your quote in few clicks!"
      
    
    }
  },
  fr: {
    translation: {
      home: {
        ecoGreenProjects: "Projets Écologiques",
        goGreenSaveGreen: "Adoptez l'énergie solaire et économisez",
        contactUs: "CONTACTEZ-NOUS",
        ourServices: "NOS SERVICES"
      },
      about: {
        aboutUsTitle: "À propos de nous",
        aboutUsText1: "Fondée par Sebastien, qui est tombé amoureux des paysages époustouflants de Calp, notre mission est de permettre aux habitants locaux et aux expatriés d'adopter un mode de vie durable grâce aux solutions d'énergie solaire.",
        aboutUsText2: "Imaginez réduire votre facture d'électricité de manière incroyable de 85%! Nos installations de panneaux solaires ne sont pas seulement un choix intelligent, elles sont une révolution pour votre porte-monnaie et l'environnement.",
        aboutUsText3: "Depuis plus de 15 ans, nous sommes bien établis dans l'industrie des panneaux solaires en Belgique, ayant réussi à réaliser un grand nombre d'installations. Aujourd'hui, nous apportons notre expertise sur les côtes ensoleillées de Calp, Altea, Moraira, Benissa et au-delà.",
        quoteButton: "Demandez un devis gratuit"
      },
      services: {
        ourServicesTitle: "Nos Services",
        solarPanelInstallation: "Installation de panneaux solaires",
        airConditioningInstallation: "Installation de climatisation",
        poolHeatPumpInstallation: "Installation de pompe à chaleur pour piscine",
        heatPumpBoilerInstallation: "Installation de chaudière à pompe à chaleur",
        electricCarChargingStation: "Station de recharge pour voitures électriques",
        serviceDetailButton: "Nos services en détail",
        freeQuoteButton: "Demandez un devis gratuit",
        friendlyPersonalService: "Service amical et personnalisé",
        installationExperience: "Grande expérience en installation et maintenance",
        available: "Disponible 24/7"
      },
      ourmission: {
        title: "Notre mission",
        paragraph1: "Chez Eco Green Projects, nous nous engageons à transformer la façon dont vous percevez l'énergie. Avec une mission centrée sur l'économie d'argent grâce à l'énergie solaire tout en favorisant l'éco-responsabilité, nous nous efforçons de rendre la vie durable accessible à tous. Nos valeurs fondamentales d'innovation, de solutions intelligentes et de durabilité nous guident dans chaque projet que nous entreprenons.",
        paragraph2: "Fort de plus de 15 ans d'expérience dans le domaine de l'énergie solaire et de nombreuses installations réussies, nous nous sommes établis comme un leader de confiance dans ce secteur. Notre passion pour les énergies renouvelables est enracinée dans notre amour pour Calp, une ville bénie par 300 jours de soleil par an, ce qui en fait un endroit idéal pour l'installation de panneaux solaires.",
        paragraph3: "Nous proposons une gamme de services, incluant des installations solides et des projets achevés dans les délais, conçus pour vous aider à économiser de l'argent tout en bénéficiant des avantages de l'énergie propre. Notre approche axée sur le client s'adresse à la fois aux expatriés et aux habitants locaux possédant des maisons dans le sud de l'Espagne, afin de répondre aux besoins uniques de notre clientèle diversifiée.",
        viewProjects: "Voir nos projets",
        askQuote: "Demander un devis gratuit"
      },
      choose: {
        title: "Pourquoi nous choisir",
        description: "Nous vous offrons un professionnel fiable et expérimenté pour vous fournir des services de qualité.",
        experienced_team: {
          name: "Équipe expérimentée et qualifiée",
          summary: "Notre équipe est hautement expérimentée et qualifiée, prête à résoudre tous les problèmes que vous pourriez rencontrer"
        },
        reliable_service: {
          name: "Service fiable et efficace",
          summary: "Nous nous efforçons de fournir un service fiable et efficace à nos clients. Nous arrivons à l'heure, terminons rapidement et efficacement"
        },
        competitive_pricing: {
          name: "Tarification compétitive",
          summary: "Nous offrons des prix compétitifs sans compromettre la qualité de notre travail"
        },
        customer_satisfaction: {
          name: "Satisfaction client",
          summary: "Nous écoutons vos besoins et préoccupations, et nous travaillons avec vous pour trouver la meilleure solution"
        },
        fast_delivery: {
          name: "Livraison rapide",
          summary: "Nous sommes rapides pour livrer des devis et des installations"
        }
      },
      navbar: {
        home: "ACCUEIL",
        about: "À PROPOS DE NOUS",
        services: "SERVICES",
        projects: "PROJETS",
        faqs: "FAQ",
        contact: "CONTACT",
        ask_for_quote: "Demander un devis gratuit"
      },
      footer: {
        description: "Eco Green Projects est votre partenaire et expert en énergie solaire pour votre projet dans le sud de l'Espagne. Notre mission est de vous aider à économiser de l'argent grâce à notre installation.",
        our_services: "Nos Services",
        solar_panel_installation: "Installation de panneaux solaires",
        air_conditioning_installation: "Solutions d'installation de climatisation",
        pool_heat_pump_installation: "Installation de pompe à chaleur pour piscine",
        electric_car_charging_station_installation: "Installation de station de recharge pour voitures électriques",
        contact: "Contact",
        follow_us: "Suivez-nous",
        our_presence: "Notre Présence",
        solar_installation_in_calpe: "Installation solaire à Calpe",
        terms_conditions: "Conditions générales",
        privacy_policy: "Politique de confidentialité"
      },
      aboutpage: {
        title: "À propos de nous",
        heading: "Chez Eco Green Project, nous nous engageons à transformer la façon dont vous utilisez l'énergie.",
        founder_info: "Fondé par Sébastien, qui est tombé amoureux des paysages à couper le souffle de Calp, notre mission est de permettre aux habitants et aux expatriés d'adopter un mode de vie durable grâce aux solutions d'énergie solaire.",
        savings: "Imaginez réduire votre facture d'électricité de 85%! Nos installations de panneaux solaires ne sont pas seulement un choix intelligent, elles sont un véritable changement pour votre porte-monnaie et pour l'environnement.",
        experience: "Avec plus de 15 ans d'expérience dans l'industrie des panneaux solaires en Belgique, où nous avons réalisé de nombreuses installations, nous apportons aujourd'hui notre expertise sur les côtes ensoleillées de Calp, Altea, Moraira, Benissa et au-delà.",
        services_title: "Nos services complets comprennent :",
        services: [
          "Installation de panneaux solaires : maximisez vos économies d'énergie avec notre technologie solaire de pointe.",
          "Solutions de climatisation : gardez votre maison fraîche et confortable pendant les mois chauds.",
          "Pompes à chaleur pour piscine : profitez de votre piscine toute l'année grâce à des solutions de chauffage écoénergétiques.",
          "Réservoirs d'eau chaude thermodynamiques : exploitez l'énergie renouvelable pour vos besoins en eau chaude.",
          "Bornes électriques : assurez-vous que votre maison est équipée des dernières solutions électriques."
        ],
        closing: "Rejoignez-nous dans notre engagement pour créer un environnement écologique tout en économisant de l'argent. Laissez Eco Green Project vous guider dans votre parcours vers un mode de vie durable. Contactez-nous dès aujourd'hui pour savoir comment nous pouvons vous aider à passer à l'énergie solaire et profiter des avantages d'un mode de vie plus vert!"
      },
      result: {
        title: "Nos Services",
        services: [
          {
            title: "Installation de panneaux solaires",
            description: "<span style=display:block;><strong>Chez Eco Green Projects, nous proposons des services professionnels d'installation de panneaux solaires pour vous aider à exploiter l'énergie renouvelable et réduire vos factures d'énergie.</strong></span></br><span style=display:block;> Nos techniciens certifiés assurent une installation experte en utilisant des panneaux solaires de haute qualité pour garantir un maximum d'efficacité et un minimum de perturbations dans votre quotidien.</span></br><span style=display:block;> Rejoignez la révolution de l'énergie verte et faites le choix de l'énergie durable et rentable dès aujourd'hui.</span>",
            button: "Demander un devis gratuit",
            altText: "Installation de panneaux solaires"
          },
          {
            title: "Solutions d'installation de climatisation",
            description: "<span style=display:block;><strong>Chez Eco Green Projects, nous proposons des services professionnels d'installation de climatisation pour garantir un confort optimal pour votre maison ou votre entreprise.</strong></span></br><span style=display:block;> Notre équipe certifiée HVAC utilise des unités de haute qualité et les dernières technologies pour offrir des solutions de refroidissement efficaces et économes en énergie, adaptées à vos besoins.</span></br><span style=display:block;> Améliorez votre environnement intérieur et profitez d'un climat parfaitement contrôlé tout au long de l'année.</span>",
            button: "Demander un devis gratuit",
            altText: "Installation de climatisation"
          },
          {
            title: "Installation de pompes à chaleur pour piscine",
            description: "<span style=display:block;><strong>Chez Eco Green Projects, nous proposons des services professionnels d'installation de pompes à chaleur pour piscine afin de vous aider à profiter d'une expérience de baignade confortable toute l'année.</strong></span></br><span style=display:block;> Nos pompes à chaleur écoénergétiques sont adaptées à la taille et aux besoins de votre piscine, garantissant des performances optimales et des économies d'énergie.</span></br><span style=display:block;> Améliorez votre piscine avec des solutions de chauffage écologiques qui réduisent les coûts énergétiques et prolongent la saison de baignade.</span>",
            button: "Demander un devis gratuit",
            altText: "Installation de pompes à chaleur pour piscine"
          },
          {
            title: "Installation de chaudières à pompe à chaleur",
            description: "<span style=display:block;><strong>Chez Eco Green Projects, nous fournissons des installations de chaudières à pompe à chaleur efficaces et durables pour les maisons et les entreprises.</strong></span></br><span style=display:block;> Nos professionnels certifiés utilisent des technologies de pompe à chaleur de pointe pour réduire les coûts énergétiques et minimiser votre empreinte carbone.</span></br><span style=display:block;> Profitez d'une installation sans tracas et adoptez un chauffage économe en énergie qui soutient un avenir plus vert.</span>",
            button: "Demander un devis gratuit",
            altText: "Installation de chaudières à pompe à chaleur"
          },
          {
            title: "Installation de bornes de recharge pour voitures électriques",
            description: "<span style=display:block;><strong>Chez Eco Green Projects, nous nous spécialisons dans l'installation de bornes de recharge pour véhicules électriques (VE) afin de soutenir le transport durable.</strong></span></br><span style=display:block;> Notre équipe d'experts réalise des installations efficaces et fiables avec des équipements de haute qualité, garantissant des performances optimales et un minimum de perturbations dans votre quotidien.</span></br><span style=display:block;> Passez à un avenir plus vert avec nos solutions de recharge VE adaptées à votre maison ou à votre entreprise.</span>",
            button: "Demander un devis gratuit",
            altText: "Installation de bornes de recharge pour voitures électriques"
          }
        ]
      },
      
      project: {
        title: "Nos Projets",
        projectTitle: "NOTRE PROJET À CALPE",
        description1: "Nous sommes heureux de vous présenter notre projet inaugural à Calpe.",
        description2: "Ce projet comprend une installation solaire complète équipée d'un système de batteries. De plus, nous avons mis en place la climatisation, une pompe à chaleur et une station de recharge pour véhicules électriques afin d'améliorer la durabilité et l'efficacité de la propriété.",
        energyGraph: "Graphique de Consommation d'Énergie",
        installationOverview: "Aperçu de l'Installation"
      },
      "faqTitle": "FAQ : Services d'énergie solaire et d'installation dans le sud de l'Espagne",
  "faq1": {
    "title": "Combien coûte l'installation de panneaux solaires dans le sud de l'Espagne ?",
    "content": "Le coût de l'installation de panneaux solaires dans le sud de l'Espagne peut varier en fonction de plusieurs facteurs comme la taille de votre maison, la capacité du système et vos besoins en énergie. Pour les propriétaires dans des zones comme Calp, Altea et Benissa, les prix varient généralement entre 5 000 € et 12 000 € pour une installation complète. Les expatriés peuvent bénéficier d'économies importantes à long terme sur leurs factures d'énergie. Pour obtenir un prix précis adapté à votre maison, nous offrons des devis gratuits et sans engagement."
  },
  "faq2": {
    "title": "Quel est le ROI (Retour sur Investissement) pour les panneaux solaires en Espagne ?",
    "content": "Le ROI pour les panneaux solaires en Espagne est excellent, surtout le long de la Costa Blanca, où le temps ensoleillé est abondant. La plupart des propriétaires voient un retour sur investissement dans les 4 à 5 ans, en fonction de la taille du système et de la consommation d'énergie. Pour les expatriés dans des zones comme Moraira et Benissa, les économies peuvent être encore plus importantes en raison de la réduction de la dépendance aux sources d'énergie traditionnelles. L'énergie solaire peut réduire vos factures d'électricité jusqu'à 70 %, ce qui en fait un investissement rentable à long terme."
  },
  "faq3": {
    "title": "Pourquoi choisir votre entreprise pour l'installation de panneaux solaires ?",
    "content": "Fort de 20 ans d'expérience en Belgique, nous avons acquis une réputation de qualité et de fiabilité. Nous apportons ce même niveau d'expertise dans le sud de l'Espagne, en desservant des zones telles que Calp, Altea, et au-delà. Nos avantages clés incluent :<ul><li>Réponses rapides : Nous fournissons des devis rapidement, vous n'aurez donc pas à attendre.</li><li>Installation efficace : Nous réalisons les installations plus rapidement que la plupart de nos concurrents, minimisant ainsi les perturbations.</li><li>Soutien multilingue : Notre équipe parle anglais, espagnol, français et néerlandais, facilitant ainsi la communication avec les expatriés.</li></ul>Nous sommes votre partenaire de confiance pour des solutions énergétiques solaires sur la Costa Blanca."
  },
  "faq4": {
    "title": "Combien de temps faut-il pour installer des panneaux solaires ?",
    "content": "Nous nous efforçons de réaliser des installations de panneaux solaires rapidement. En général, le processus prend entre 1 et 3 jours, selon la taille du système et la complexité de l'installation. Une fois que vous avez reçu un devis et décidé de poursuivre, nous pouvons généralement planifier l'installation dans un délai d'une semaine pour les propriétés à Calp, Altea, Moraira, Benissa et les environs. Notre objectif est de rendre le processus aussi rapide et sans tracas que possible."
  },
  "faq5": {
    "title": "Comment obtenir un devis pour l'installation de panneaux solaires ?",
    "content": "Obtenir un devis gratuit pour l'installation de panneaux solaires est facile. Vous pouvez :<ul><li>Nous appeler ou nous envoyer un e-mail, et notre équipe organisera une consultation.</li><li>Remplir le formulaire sur notre site Web, et nous vous répondrons dans les 24 heures.</li></ul>Nous proposons des devis personnalisés en fonction de vos besoins en énergie et des spécifications de votre propriété, vous garantissant ainsi un prix précis et compétitif dans le sud de l'Espagne."
  },
  "faq6": {
    "title": "Quels types d'installations proposez-vous ?",
    "content": "Nous sommes spécialisés dans une variété de solutions énergétiques, y compris :<ul><li>Installation de panneaux solaires : des systèmes efficaces et rentables adaptés à votre domicile.</li><li>Installation de climatisation : des unités écoénergétiques qui maintiennent votre maison confortable tout au long de l'année.</li><li>Installation de pompe à chaleur pour piscine : prolongez votre saison de baignade avec un système de chauffage écologique.</li><li>Installation de chaudière à pompe à chaleur : un chauffage durable pour l'approvisionnement en eau de votre maison.</li><li>Installation de borne de recharge pour voiture électrique : des solutions pratiques de recharge à domicile pour votre véhicule électrique.</li></ul>Chaque service est disponible à Calp, Altea, Moraira, Benissa et dans les régions environnantes."
  },
  "faq7": {
    "title": "Quelles zones desservez-vous dans le sud de l'Espagne ?",
    "content": "Nous servons fièrement les propriétaires et expatriés dans toute la Costa Blanca, y compris :<ul><li>Calp</li><li>Altea</li><li>Moraira</li><li>Benissa</li></ul>Que vous recherchiez des solutions énergétiques solaires, l'installation de climatiseurs ou des bornes de recharge pour véhicules électriques, nous fournissons des services de haute qualité et fiables dans toute la région."
  },
    contactButtonText: "Votre devis en quelques clics !"

    }
  },
  nr: {
    translation: {
      home: {
        ecoGreenProjects: "Eco Green Projecten",
        goGreenSaveGreen: "Ga groen & bespaar groen met zonne-energie",
        contactUs: "NEEM CONTACT OP",
        ourServices: "ONZE DIENSTEN"
      },
      about: {
        aboutUsTitle: "Over ons",
        aboutUsText1: "Opgericht door Sebastien, die verliefd werd op de adembenemende landschappen van Calp, is onze missie om zowel de lokale bevolking als expats in staat te stellen een duurzame levensstijl omarmen door middel van zonne-energieoplossingen.",
        aboutUsText2: "Stel je voor dat je je elektriciteitsrekening met maar liefst 85% verlaagt! Onze zonnepaneleninstallaties zijn niet alleen een slimme keuze, maar ook een gamechanger voor je portemonnee en het milieu.",
        aboutUsText3: "Al meer dan 15 jaar zijn wij goed gevestigd in de zonnepaneelindustrie in België, en hebben we met succes verschillende installaties afgerond. Vandaag brengen we onze expertise naar de zonnige kusten van Calp, Altea, Moraira, Benissa en verder.",
        quoteButton: "Vraag een gratis offerte aan"
      },
      services: {
        ourServicesTitle: "Onze Diensten",
        solarPanelInstallation: "Zonnepaneelinstallatie",
        airConditioningInstallation: "Airconditioninginstallatie",
        poolHeatPumpInstallation: "Installatie van zwembadwarmtepomp",
        heatPumpBoilerInstallation: "Installatie van warmtepompboiler",
        electricCarChargingStation: "Laadstation voor elektrische auto’s",
        serviceDetailButton: "Onze diensten in detail",
        freeQuoteButton: "Vraag een gratis offerte aan",
        friendlyPersonalService: "Vriendelijke en persoonlijke service",
        installationExperience: "Veel ervaring in installatie en onderhoud",
        available: "24/7 beschikbaar"
      },
      ourmission: {
        title: "Onze missie",
        paragraph1: "Bij Eco Green Projects zijn we toegewijd aan het transformeren van de manier waarop u over energie denkt. Met een missie die zich richt op geld besparen door middel van zonne-energie, terwijl we milieuvriendelijkheid bevorderen, streven we ernaar om duurzaam leven toegankelijk te maken voor iedereen. Onze kernwaarden van innovatie, slimme oplossingen en duurzaamheid leiden ons bij elk project dat we ondernemen.",
        paragraph2: "Met meer dan 15 jaar ervaring in de zonne-energie-industrie en veel succesvolle installaties, hebben we onszelf gevestigd als een vertrouwde leider op dit gebied. Onze passie voor hernieuwbare energie is geworteld in onze liefde voor Calp, een locatie die wordt gekenmerkt door 300 zonnige dagen per jaar, waardoor het de perfecte plaats is voor de installatie van zonnepanelen.",
        paragraph3: "We bieden een scala aan diensten aan, waaronder degelijke installaties en tijdige projectafronding, allemaal ontworpen om u te helpen geld te besparen en tegelijkertijd te profiteren van de voordelen van schone energie. Onze klantgerichte benadering is gericht op zowel expats als lokale bewoners die huizen bezitten in Zuid-Spanje, waardoor we in de unieke behoeften van onze diverse klantenkring kunnen voorzien.",
        viewProjects: "Bekijk onze projecten",
        askQuote: "Vraag een gratis offerte aan"
      },
      choose: {
        title: "Waarom voor ons kiezen",
        description: "Kiezen voor ons betekent dat je een betrouwbare en ervaren professional krijgt die de expertise heeft om kwaliteitsdiensten te leveren.",
        experienced_team: {
          name: "Ervaren en gekwalificeerd team",
          summary: "Ons team is hooggekwalificeerd en goed uitgerust om elk probleem aan te pakken waarmee je mogelijk te maken hebt"
        },
        reliable_service: {
          name: "Betrouwbare en efficiënte service",
          summary: "We streven ernaar om betrouwbare en efficiënte service te bieden aan onze klanten. We komen op tijd, werken snel en efficiënt"
        },
        competitive_pricing: {
          name: "Concurrerende prijzen",
          summary: "We bieden concurrerende prijzen zonder concessies te doen aan de kwaliteit van ons werk"
        },
        customer_satisfaction: {
          name: "Klanttevredenheid",
          summary: "We luisteren naar je behoeften en zorgen en werken met je samen om de beste oplossing te vinden"
        },
        fast_delivery: {
          name: "Snelle levering",
          summary: "We zijn snel met het leveren van offertes en installaties"
        }
      },
      navbar: {
        home: "START",
        about: "OVER ONS",
        services: "DIENSTEN",
        projects: "PROJECTEN",
        faqs: "FAQ'S",
        contact: "CONTACT",
        ask_for_quote: "Vraag een gratis offerte aan"
      },
      footer: {
        description: "Eco Green Projects is uw partner en expert op het gebied van zonne-energie voor uw project in het zuiden van Spanje. Onze missie is om u te helpen geld te besparen dankzij onze installatie.",
        our_services: "Onze Diensten",
        solar_panel_installation: "Zonnepaneelinstallatie",
        air_conditioning_installation: "Installatieoplossingen voor airconditioning",
        pool_heat_pump_installation: "Installatie van zwembadwarmtepompen",
        electric_car_charging_station_installation: "Installatie van oplaadstations voor elektrische auto's",
        contact: "Contact",
        follow_us: "Volg Ons",
        our_presence: "Onze Aanwezigheid",
        solar_installation_in_calpe: "Zonne-installatie in Calpe",
        terms_conditions: "Algemene Voorwaarden",
        privacy_policy: "Privacybeleid"
      },
      aboutpage: {
        title: "Over ons",
        heading: "Bij Eco Green Project zetten we ons in om de manier waarop u energie benut te transformeren.",
        founder_info: "Opgericht door Sebastien, die verliefd werd op het adembenemende landschap van Calp, is onze missie om zowel de lokale bevolking als expats in staat te stellen een duurzaam leven te omarmen door middel van zonne-energieoplossingen.",
        savings: "Stel je voor dat je je elektriciteitsrekening met maar liefst 85% kunt verlagen! Onze zonnepaneelinstallaties zijn niet alleen een slimme keuze, maar ook een gamechanger voor je portemonnee en het milieu.",
        experience: "Al meer dan 15 jaar zijn we goed gevestigd in de zonnepaneelindustrie in België, waar we verschillende installaties met succes hebben voltooid. Vandaag brengen we onze expertise naar de zonnige kusten van Calp, Altea, Moraira, Benissa en verder.",
        services_title: "Onze volledige reeks diensten omvat:",
        services: [
          "Zonnepaneelinstallatie: maximaliseer je energiebesparingen met onze geavanceerde zonne-energietechnologie.",
          "Airconditioningoplossingen: houd je huis koel en comfortabel tijdens de hete zomermaanden.",
          "Zwembadwarmtepompen: geniet het hele jaar door van je zwembad met energiezuinige verwarmingsoplossingen.",
          "Thermodynamische warmwaterboilers: benut hernieuwbare energie voor je warmwaterbehoeften.",
          "Elektrische laadstations: zorg ervoor dat je huis is uitgerust met de nieuwste elektrische oplossingen."
        ],
        closing: "Sluit je aan bij ons om een milieuvriendelijkere omgeving te creëren en tegelijkertijd geld te besparen. Laat Eco Green Project je begeleiden op je weg naar duurzaam leven. Neem vandaag nog contact met ons op om te ontdekken hoe wij je kunnen helpen om over te schakelen naar zonne-energie en te profiteren van de voordelen van een groenere levensstijl!"
      },
      result: {
        title: "Onze Diensten",
        services: [
          {
            title: "Zonnepaneel Installatie",
            description: "<span style=display:block;><strong>Bij Eco Green Projects bieden we professionele zonnepaneelinstallatiediensten aan om u te helpen hernieuwbare energie te benutten en uw energiekosten te verlagen.</strong></span></br><span style=display:block;> Onze gecertificeerde technici zorgen voor deskundige installatie, met gebruik van zonnepanelen van hoge kwaliteit om maximale efficiëntie en minimale verstoring van uw dagelijkse routine te waarborgen.</span></br><span style=display:block;> Sluit u aan bij de groene energierevolutie en maak vandaag de overstap naar duurzame, kosteneffectieve energie.</span>",
            button: "Vraag een gratis offerte aan",
            altText: "Zonnepaneel Installatie"
          },
          {
            title: "Airconditioning Installatie Oplossingen",
            description: "<span style=display:block;><strong>Bij Eco Green Projects bieden we professionele airconditioning-installatiediensten aan om optimaal comfort in uw huis of bedrijf te garanderen.</strong></span></br><span style=display:block;> Ons gecertificeerde HVAC-team maakt gebruik van hoogwaardige units en de nieuwste technologie om energie-efficiënte koeloplossingen te bieden die zijn afgestemd op uw behoeften.</span></br><span style=display:block;> Verbeter uw binnenomgeving en geniet het hele jaar door van een perfect gecontroleerd klimaat.</span>",
            button: "Vraag een gratis offerte aan",
            altText: "Airconditioning Installatie"
          },
          {
            title: "Zwembad Warmtepomp Installatie",
            description: "<span style=display:block;><strong>Bij Eco Green Projects bieden we professionele installatie van zwembadwarmtepompen aan om u te helpen het hele jaar door te genieten van een comfortabel zwembad.</strong></span></br><span style=display:block;> Onze energie-efficiënte warmtepompen worden afgestemd op de grootte en behoeften van uw zwembad, waardoor optimale prestaties en kostenbesparingen worden gegarandeerd.</span></br><span style=display:block;> Verbeter uw zwembad met milieuvriendelijke verwarmingsoplossingen die de energiekosten verlagen en het zwemseizoen verlengen.</span>",
            button: "Vraag een gratis offerte aan",
            altText: "Zwembad Warmtepomp Installatie"
          },
          {
            title: "Warmtepomp Boiler Installatie",
            description: "<span style=display:block;><strong>Bij Eco Green Projects bieden we efficiënte en duurzame warmtepompboilerinstallaties voor woningen en bedrijven.</strong></span></br><span style=display:block;> Onze gecertificeerde professionals maken gebruik van geavanceerde warmtepomptechnologie om de energiekosten te verlagen en uw CO2-voetafdruk te minimaliseren.</span></br><span style=display:block;> Ervaar probleemloze installatie en maak gebruik van energiezuinige verwarming die bijdraagt aan een groenere toekomst.</span>",
            button: "Vraag een gratis offerte aan",
            altText: "Warmtepomp Boiler Installatie"
          },
          {
            title: "Oplaadstation voor Elektrische Auto's Installatie",
            description: "<span style=display:block;><strong>Bij Eco Green Projects zijn we gespecialiseerd in het installeren van oplaadstations voor elektrische voertuigen (EV) om duurzaam transport te ondersteunen.</strong></span></br><span style=display:block;> Ons deskundige team biedt efficiënte en betrouwbare installaties met hoogwaardige apparatuur, zodat optimale prestaties worden gegarandeerd met minimale verstoring van uw dagelijkse routine.</span></br><span style=display:block;> Stap over op een groenere toekomst met onze op maat gemaakte EV-oplossingen voor uw huis of bedrijf.</span>",
            button: "Vraag een gratis offerte aan",
            altText: "Oplaadstation voor Elektrische Auto's Installatie"
          }
        ]
      },
      
      project: {
        title: "Onze Projecten",
        projectTitle: "ONS PROJECT IN CALPE",
        description1: "We zijn verheugd ons eerste project in Calpe te presenteren.",
        description2:  "Dit project bevat een uitgebreide zonne-installatie met een batterij-systeem. Daarnaast hebben we airconditioning, een warmtepomp en een oplaadstation voor elektrische voertuigen geïmplementeerd om de duurzaamheid en efficiëntie van het pand te verbeteren.",
        energyGraph: "Energieverbruik Grafiek",
        installationOverview: "Installatie Overzicht"
      },
      "faqTitle": "Veelgestelde vragen: Zonne-energie en Installatiediensten in Zuid-Spanje",
      "faq1": {
        "title": "Wat kost een installatie van zonnepanelen in Zuid-Spanje?",
        "content": "De kosten van een zonnepaneleninstallatie in Zuid-Spanje kunnen variëren, afhankelijk van factoren zoals de grootte van uw huis, de capaciteit van het systeem en uw energiebehoeften. Voor huiseigenaren in gebieden zoals Calp, Altea en Benissa liggen de prijzen meestal tussen de €5.000 en €12.000 voor een volledige zonne-energiesetup. Expats kunnen profiteren van aanzienlijke besparingen op hun energiekosten op de lange termijn. Voor een nauwkeurige prijs op maat van uw huis, bieden wij gratis en vrijblijvende offertes aan."
      },
      "faq2": {
        "title": "Wat is het ROI (rendement op investering) voor zonnepanelen in Spanje?",
        "content": "Het ROI voor zonnepanelen in Spanje is uitstekend, vooral langs de Costa Blanca, waar veel zonneschijn is. De meeste huiseigenaren zien een terugverdientijd van 4 tot 5 jaar, afhankelijk van de grootte van het systeem en het energieverbruik. Voor expats in gebieden zoals Moraira en Benissa kunnen de besparingen nog groter zijn door minder afhankelijkheid van traditionele energiebronnen. Zonne-energie kan uw elektriciteitsrekening met wel 70% verlagen, waardoor het een slimme langetermijninvestering is."
      },
      "faq3": {
        "title": "Waarom zou ik uw bedrijf kiezen voor de installatie van zonnepanelen?",
        "content": "Met 20 jaar ervaring in België hebben we een reputatie opgebouwd voor kwaliteit en betrouwbaarheid. We brengen hetzelfde niveau van expertise naar Zuid-Spanje, en bedienen gebieden zoals Calp, Altea en verder. Onze belangrijkste voordelen zijn onder andere:<ul><li>Snelle reacties: We verstrekken snel offertes, zodat u niet hoeft te wachten.</li><li>Efficiënte installatie: We installeren sneller dan de meeste concurrenten, zodat er minimale verstoring is.</li><li>Meertalige ondersteuning: Ons team spreekt Engels, Spaans, Frans en Nederlands, waardoor communicatie met expats gemakkelijker is.</li></ul>Wij zijn uw vertrouwde partner voor zonne-energieoplossingen aan de Costa Blanca."
      },
      "faq4": {
        "title": "Hoe lang duurt het om zonnepanelen te installeren?",
        "content": "Wij staan bekend om onze snelle installatie van zonnepanelen. Het proces duurt meestal tussen de 1 en 3 dagen, afhankelijk van de grootte van het systeem en de complexiteit van de installatie. Zodra u een offerte heeft ontvangen en besluit door te gaan, kunnen we de installatie meestal binnen een week plannen voor woningen in Calp, Altea, Moraira, Benissa en omstreken. Ons doel is om het proces zo snel en probleemloos mogelijk te maken."
      },
      "faq5": {
        "title": "Hoe krijg ik een offerte voor zonnepaneleninstallatie?",
        "content": "Een gratis offerte voor zonnepanelen is eenvoudig te verkrijgen. U kunt:<ul><li>Ons bellen of e-mailen, en ons team zal een afspraak maken.</li><li>Het formulier op onze website invullen, en wij reageren binnen 24 uur.</li></ul>We bieden gepersonaliseerde offertes aan op basis van uw energiebehoeften en de specificaties van uw woning, zodat u de meest nauwkeurige en concurrerende prijs krijgt die beschikbaar is in Zuid-Spanje."
      },
      "faq6": {
        "title": "Welke soorten installaties bieden jullie aan?",
        "content": "We zijn gespecialiseerd in verschillende energieoplossingen, waaronder:<ul><li>Installatie van zonnepanelen: Efficiënte en kosteneffectieve systemen op maat van uw woning.</li><li>Installatie van airconditioning: Energiezuinige units die uw huis het hele jaar door comfortabel houden.</li><li>Installatie van zwembadwarmtepompen: Verlengen van het zwemseizoen met een milieuvriendelijke verwarmingsoplossing.</li><li>Installatie van warmtepompboilers: Duurzaam verwarmen van het water in uw woning.</li><li>Installatie van oplaadstations voor elektrische voertuigen: Gemakkelijke oplaadoplossingen voor uw elektrische voertuig.</li></ul>Elke service is beschikbaar in Calp, Altea, Moraira, Benissa en de omliggende gebieden."
      },
      "faq7": {
        "title": "Welke gebieden bedient u in Zuid-Spanje?",
        "content": "We bedienen met trots huiseigenaren en expats in de hele Costa Blanca, inclusief:<ul><li>Calp</li><li>Altea</li><li>Moraira</li><li>Benissa</li></ul>Of u nu op zoek bent naar zonne-energieoplossingen, airconditioninginstallaties of oplaadstations voor elektrische voertuigen, wij bieden hoogwaardige, betrouwbare diensten in de regio."
      },
      contactButtonText: "Uw offerte in een paar klikken!"

    }
  },
  al: {
    translation: {
      home: {
        ecoGreenProjects: "Projekte të Gjelbërta Ekologjike",
        goGreenSaveGreen: "Shkoni Green dhe Kurseni Green me Energi Solare",
        contactUs: "NA KONTAKTONI",
        ourServices: "SHERBIMET TONA"
      },
      about: {
        aboutUsTitle: "Über uns",
        aboutUsText1: "Gegründet von Sebastien, der sich in die atemberaubenden Landschaften von Calp verliebte, ist es unsere Mission, Einheimische und Expats zu befähigen, nachhaltiges Leben durch Solarenergie-Lösungen zu umarmen.",
        aboutUsText2: "Stellen Sie sich vor, Ihre Stromrechnung um unglaubliche 85% zu senken! Unsere Solarpanel-Installationen sind nicht nur eine clevere Wahl, sie sind ein Wendepunkt für Ihren Geldbeutel und die Umwelt.",
        aboutUsText3: "Seit über 15 Jahren sind wir in der Solarindustrie in Belgien gut etabliert und haben zahlreiche Installationen erfolgreich abgeschlossen. Heute bringen wir unsere Expertise an die sonnigen Küsten von Calp, Altea, Moraira, Benissa und darüber hinaus.",
        quoteButton: "Fordern Sie ein kostenloses Angebot an"
      },
        services: {
          ourServicesTitle: "Shërbimet tona",
          solarPanelInstallation: "Instalimi i Paneleve Solare",
          airConditioningInstallation: "Instalimi i Klimatizimit",
          poolHeatPumpInstallation: "Instalimi i pompës së ngrohjes për pishinë",
          heatPumpBoilerInstallation: "Instalimi i kaldajës me pompë ngrohje",
          electricCarChargingStation: "Stacioni i Karikimit të Makina Elektrike",
          serviceDetailButton: "Shërbimet tona në detaje",
          freeQuoteButton: "Kërkoni një ofertë falas",
          friendlyPersonalService: "Shërbim miqësor dhe personal",
          installationExperience: "Eksperiencë e pasur në Instalime dhe Mirëmbajtje",
          available: "Disponueshëm 24/7"
        },
        ourmission: {
          title: "Misioni ynë",
          paragraph1: "Në Eco Green Projects, jemi të përkushtuar për të transformuar mënyrën se si mendoni për energjinë. Me një mision të përqendruar në kursimin e parave përmes energjisë diellore, ndërkohë që promovojmë miqësinë me mjedisin, ne synojmë ta bëjmë jetesën e qëndrueshme të mundshme për të gjithë. Vlerat tona kryesore të inovacionit, zgjidhjeve të zgjuara dhe qëndrueshmërisë na udhëzojnë në çdo projekt që ndërmarrim.",
          paragraph2: "Me më shumë se 15 vjet eksperiencë në industrinë e energjisë diellore dhe shumë instalime të suksesshme, ne kemi krijuar një emër si lider i besueshëm në këtë fushë. Pasioni ynë për energjinë e rinovueshme është i rrënjosur në dashurinë tonë për Calp, një qytet i bekuar me 300 ditë diellore në vit, duke e bërë atë vendin e përsosur për instalimin e paneleve diellore.",
          paragraph3: "Ne ofrojmë një gamë shërbimesh, duke përfshirë instalime të forta dhe përfundime të projekteve në kohë, të gjitha të dizajnuara për t'ju ndihmuar të kurseni para, duke shijuar përfitimet e energjisë së pastër. Qasja jonë e orientuar nga klienti është e fokusuar në ekspatët dhe lokalët që kanë prona në jug të Spanjës, duke siguruar që të përmbushim nevojat e veçanta të klientëve tanë të ndryshëm.",
          viewProjects: "Shihni projektet tona",
          askQuote: "Kërkoni një ofertë falas"
        },
        choose: {
          title: "Warum uns wählen",
          description: "Die Wahl für uns bedeutet, dass Sie einen zuverlässigen und erfahrenen Fachmann erhalten, der die Expertise hat, qualitativ hochwertige Dienstleistungen zu liefern.",
          experienced_team: {
            name: "Erfahrenes und qualifiziertes Team",
            summary: "Unser Team ist hochqualifiziert und gut ausgestattet, um jedes Problem zu lösen, mit dem Sie konfrontiert sein könnten"
          },
          reliable_service: {
            name: "Zuverlässiger und effizienter Service",
            summary: "Wir bemühen uns, unseren Kunden einen zuverlässigen und effizienten Service zu bieten. Wir kommen pünktlich, erledigen die Arbeit schnell und effizient"
          },
          competitive_pricing: {
            name: "Wettbewerbsfähige Preise",
            summary: "Wir bieten wettbewerbsfähige Preise, ohne die Qualität unserer Arbeit zu beeinträchtigen"
          },
          customer_satisfaction: {
            name: "Kundenzufriedenheit",
            summary: "Wir hören auf Ihre Bedürfnisse und Anliegen und arbeiten mit Ihnen zusammen, um die beste Lösung zu finden"
          },
          fast_delivery: {
            name: "Schnelle Lieferung",
            summary: "Wir sind schnell bei der Lieferung von Angeboten und Installationen"
          }
        },
        navbar: {
          home: "STARTSEITE",
          about: "ÜBER UNS",
          services: "DIENSTLEISTUNGEN",
          projects: "PROJEKTE",
          faqs: "FAQ",
          contact: "KONTAKT",
          ask_for_quote: "Kostenloses Angebot anfordern"
        },
        footer: {
          description: "Eco Green Projects ist Ihr Partner und Experte für Solarenergie für Ihr Projekt im Süden Spaniens. Unsere Mission ist es, Ihnen zu helfen, Geld durch unsere Installation zu sparen.",
          our_services: "Unsere Dienstleistungen",
          solar_panel_installation: "Solaranlageninstallation",
          air_conditioning_installation: "Klimaanlageninstallationslösungen",
          pool_heat_pump_installation: "Installation von Wärmepumpen für Schwimmbäder",
          electric_car_charging_station_installation: "Installation von Ladestationen für Elektroautos",
          contact: "Kontakt",
          follow_us: "Folge uns",
          our_presence: "Unsere Präsenz",
          solar_installation_in_calpe: "Solaranlageninstallation in Calpe",
          terms_conditions: "Allgemeine Geschäftsbedingungen",
          privacy_policy: "Datenschutzrichtlinie"
        },
        aboutpage: {
          title: "Über uns",
          heading: "Bei Eco Green Project setzen wir uns dafür ein, die Art und Weise zu verändern, wie Sie Energie nutzen.",
          founder_info: "Gegründet von Sebastien, der sich in die atemberaubenden Landschaften von Calp verliebte, ist es unsere Mission, Einheimische und Expats zu befähigen, durch Solarlösungen ein nachhaltiges Leben zu führen.",
          savings: "Stellen Sie sich vor, Sie könnten Ihre Stromrechnung um unglaubliche 85 % senken! Unsere Solaranlagen sind nicht nur eine clevere Wahl, sondern ein echter Game-Changer für Ihr Portemonnaie und die Umwelt.",
          experience: "Seit über 15 Jahren sind wir in der Solarbranche in Belgien gut etabliert und haben erfolgreich viele Installationen abgeschlossen. Heute bringen wir unsere Expertise an die sonnigen Küsten von Calp, Altea, Moraira, Benissa und darüber hinaus.",
          services_title: "Unser vollständiges Leistungsspektrum umfasst:",
          services: [
            "Solaranlageninstallation: Maximieren Sie Ihre Energieeinsparungen mit unserer modernen Solartechnologie.",
            "Klimaanlagenlösungen: Halten Sie Ihr Zuhause während der heißen Sommermonate kühl und komfortabel.",
            "Wärmepumpen für Pools: Genießen Sie Ihren Pool das ganze Jahr über mit energieeffizienten Heizlösungen.",
            "Thermodynamische Warmwasserboiler: Nutzen Sie erneuerbare Energien für Ihre Warmwasserbedürfnisse.",
            "Elektrische Ladestationen: Stellen Sie sicher, dass Ihr Zuhause mit den neuesten elektrischen Lösungen ausgestattet ist."
          ],
          closing: "Schließen Sie sich uns an, um ein umweltfreundliches Umfeld zu schaffen und gleichzeitig Geld zu sparen. Lassen Sie Eco Green Project Sie auf Ihrem Weg zu einem nachhaltigen Leben begleiten. Kontaktieren Sie uns noch heute, um herauszufinden, wie wir Ihnen helfen können, auf Solarenergie umzusteigen und die Vorteile eines grüneren Lebensstils zu genießen!"
        },
        result: {
          title: "Unsere Dienstleistungen",
          services: [
            {
              title: "Solarpanel-Installation",
              description: "<span style=display:block;><strong>Bei Eco Green Projects bieten wir professionelle Solarpanel-Installationsdienste an, um Ihnen zu helfen, erneuerbare Energie zu nutzen und Ihre Stromrechnungen zu senken.</strong></span></br><span style=display:block;> Unsere zertifizierten Techniker bieten eine fachkundige Installation und verwenden hochwertige Solarpanels, um maximale Effizienz und minimale Störungen in Ihrem Alltag zu gewährleisten.</span></br><span style=display:block;> Schließen Sie sich der grünen Energiewende an und wechseln Sie noch heute zu nachhaltiger, kostengünstiger Energie.</span>",
              button: "Fordern Sie ein kostenloses Angebot an",
              altText: "Solarpanel-Installation"
            },
            {
              title: "Lösungen für die Installation von Klimaanlagen",
              description: "<span style=display:block;><strong>Bei Eco Green Projects bieten wir professionelle Klimaanlagen-Installationsdienste an, um für optimalen Komfort in Ihrem Zuhause oder Unternehmen zu sorgen.</strong></span></br><span style=display:block;> Unser zertifiziertes HVAC-Team verwendet hochwertige Einheiten und die neueste Technologie, um effiziente und energieeinsparende Kühllösungen anzubieten, die auf Ihre Bedürfnisse zugeschnitten sind.</span></br><span style=display:block;> Verbessern Sie Ihre Innenumgebung und genießen Sie das ganze Jahr über ein perfekt klimatisiertes Raumklima.</span>",
              button: "Fordern Sie ein kostenloses Angebot an",
              altText: "Klimaanlagen-Installation"
            },
            {
              title: "Installation von Poolwärmepumpen",
              description: "<span style=display:block;><strong>Bei Eco Green Projects bieten wir professionelle Installationen von Poolwärmepumpen an, damit Sie das ganze Jahr über ein komfortables Schwimmerlebnis genießen können.</strong></span></br><span style=display:block;> Unsere energieeffizienten Wärmepumpen sind auf die Größe und Bedürfnisse Ihres Pools zugeschnitten, um optimale Leistung und Kosteneinsparungen zu gewährleisten.</span></br><span style=display:block;> Rüsten Sie Ihren Pool mit umweltfreundlichen Heizlösungen auf, die die Energiekosten senken und die Schwimmsaison verlängern.</span>",
              button: "Fordern Sie ein kostenloses Angebot an",
              altText: "Installation von Poolwärmepumpen"
            },
            {
              title: "Installation von Wärmepumpenboilern",
              description: "<span style=display:block;><strong>Bei Eco Green Projects bieten wir effiziente und nachhaltige Installationen von Wärmepumpenboilern für Haushalte und Unternehmen an.</strong></span></br><span style=display:block;> Unsere zertifizierten Fachleute verwenden hochmoderne Wärmepumpentechnologie, um die Energiekosten zu senken und Ihren CO2-Fußabdruck zu minimieren.</span></br><span style=display:block;> Erleben Sie eine problemlose Installation und setzen Sie auf energieeffizientes Heizen, das eine grünere Zukunft unterstützt.</span>",
              button: "Fordern Sie ein kostenloses Angebot an",
              altText: "Installation von Wärmepumpenboilern"
            },
            {
              title: "Installation von Ladestationen für Elektroautos",
              description: "<span style=display:block;><strong>Bei Eco Green Projects spezialisieren wir uns auf die Installation von Ladestationen für Elektrofahrzeuge (EV), um den nachhaltigen Transport zu unterstützen.</strong></span></br><span style=display:block;> Unser Expertenteam bietet effiziente und zuverlässige Installationen mit hochwertigen Geräten, die optimale Leistung und minimale Störungen in Ihrem Alltag gewährleisten.</span></br><span style=display:block;> Wechseln Sie zu einer grüneren Zukunft mit unseren maßgeschneiderten EV-Ladelösungen für Ihr Zuhause oder Unternehmen.</span>",
              button: "Fordern Sie ein kostenloses Angebot an",
              altText: "Installation von Ladestationen für Elektroautos"
            }
          ]
        },
        
        project: {
          title: "Unsere Projekte",
          projectTitle: "UNSER PROJEKT IN CALPE",
          description1: "Wir freuen uns, unser erstes Projekt in Calpe vorzustellen.",
          description2: "Dieses Projekt umfasst eine umfassende Solarinstallation mit einem Batteriesystem. Zusätzlich haben wir Klimaanlagen, eine Wärmepumpe und eine Ladestation für Elektrofahrzeuge installiert, um die Nachhaltigkeit und Effizienz der Immobilie zu verbessern.",
          energyGraph: "Energieverbrauchsdiagramm",
          installationOverview: "Installationsübersicht"
        },
        "faqTitle": "Häufig gestellte Fragen: Solarenergie- und Installationsdienste in Südküste Spaniens",
  "faq1": {
    "title": "Was kostet eine Solaranlageninstallation in Südküste Spaniens?",
    "content": "Die Kosten einer Solaranlageninstallation in Südküste Spaniens können variieren, abhängig von Faktoren wie der Größe Ihres Hauses, der Kapazität des Systems und Ihrem Energiebedarf. Für Hausbesitzer in Gebieten wie Calp, Altea und Benissa liegen die Preise in der Regel zwischen 5.000 € und 12.000 € für eine vollständige Solaranlage. Expats können von erheblichen langfristigen Einsparungen bei ihren Energiekosten profitieren. Um einen genauen Preis für Ihr Zuhause zu erhalten, bieten wir kostenlose und unverbindliche Angebote an."
  },
  "faq2": {
    "title": "Wie hoch ist die Rendite (ROI) für Solaranlagen in Spanien?",
    "content": "Die Rendite (ROI) für Solaranlagen in Spanien ist ausgezeichnet, besonders entlang der Costa Blanca, wo es viel Sonne gibt. Die meisten Hausbesitzer erzielen eine Rendite innerhalb von 4 bis 5 Jahren, je nach Größe des Systems und Energieverbrauch. Für Expats in Gebieten wie Moraira und Benissa können die Einsparungen aufgrund der geringeren Abhängigkeit von traditionellen Energiequellen noch höher sein. Solaranlagen können Ihre Stromrechnung um bis zu 70 % senken, was sie zu einer klugen langfristigen Investition macht."
  },
  "faq3": {
    "title": "Warum sollte ich Ihr Unternehmen für die Installation von Solarpanelen wählen?",
    "content": "Mit 20 Jahren Erfahrung in Belgien haben wir uns einen Ruf für Qualität und Zuverlässigkeit erarbeitet. Wir bringen dieses Fachwissen nach Südküste Spanien und bedienen Gebiete wie Calp, Altea und darüber hinaus. Unsere wichtigsten Vorteile umfassen unter anderem:<ul><li>Schnelle Reaktionen: Wir bieten schnelle Angebote, sodass Sie nicht warten müssen.</li><li>Effiziente Installation: Wir führen Installationen schneller durch als die meisten Konkurrenten, um minimale Störungen zu gewährleisten.</li><li>Mehrsprachige Unterstützung: Unser Team spricht Englisch, Spanisch, Französisch und Niederländisch, was die Kommunikation mit Expats erleichtert.</li></ul>Wir sind Ihr vertrauenswürdiger Partner für Solarlösungen an der Costa Blanca."
  },
  "faq4": {
    "title": "Wie lange dauert die Installation von Solarpanelen?",
    "content": "Wir sind bekannt für schnelle Solarpanel-Installationen. Der Prozess dauert in der Regel 1 bis 3 Tage, je nach Größe des Systems und der Komplexität der Installation. Sobald Sie ein Angebot erhalten haben und sich entscheiden, fortzufahren, können wir die Installation normalerweise innerhalb einer Woche für Objekte in Calp, Altea, Moraira, Benissa und Umgebung planen. Unser Ziel ist es, den Prozess so schnell und problemlos wie möglich zu gestalten."
  },
  "faq5": {
    "title": "Wie erhalte ich ein Angebot für eine Solaranlageninstallation?",
    "content": "Ein kostenloses Angebot für eine Solaranlageninstallation zu erhalten ist einfach. Sie können:<ul><li>Uns anrufen oder eine E-Mail senden, und unser Team wird eine Beratung arrangieren.</li><li>Das Formular auf unserer Website ausfüllen, und wir werden innerhalb von 24 Stunden antworten.</li></ul>Wir bieten maßgeschneiderte Angebote basierend auf Ihren Energiebedürfnissen und den Spezifikationen Ihrer Immobilie, um Ihnen den genauesten und wettbewerbsfähigsten Preis in Südküste Spanien zu bieten."
  },
  "faq6": {
    "title": "Welche Installationen bieten Sie an?",
    "content": "Wir sind auf verschiedene Energielösungen spezialisiert, darunter:<ul><li>Installation von Solarpanelen: Effiziente und kostengünstige Systeme, die auf Ihr Zuhause zugeschnitten sind.</li><li>Installation von Klimaanlagen: Energieeffiziente Geräte, die Ihr Zuhause das ganze Jahr über komfortabel halten.</li><li>Installation von Pool-Wärmepumpen: Verlängern Sie die Schwimmsaison mit einer umweltfreundlichen Heizlösung.</li><li>Installation von Wärmepumpenboilern: Nachhaltige Heizung für die Warmwasserversorgung Ihres Hauses.</li><li>Installation von Ladestationen für Elektroautos: Praktische Heimladungslösungen für Ihr Elektrofahrzeug.</li></ul>Jeder Service ist in Calp, Altea, Moraira, Benissa und Umgebung verfügbar."
  },
  "faq7": {
    "title": "Welche Gebiete bedienen Sie in Südküste Spaniens?",
    "content": "Wir bedienen stolz Hausbesitzer und Expats entlang der gesamten Costa Blanca, einschließlich:<ul><li>Calp</li><li>Altea</li><li>Moraira</li><li>Benissa</li></ul>Ob Sie nach Solarlösungen, Klimaanlageninstallationen oder Ladestationen für Elektroautos suchen, wir bieten hochwertige, zuverlässige Dienstleistungen in der gesamten Region."
  },
  contactButtonText: "Ihr Angebot in wenigen Klicks!"
      
    }
  },
  es: {
    translation: {
      home: {
        ecoGreenProjects: "Proyectos Eco Verdes",
        goGreenSaveGreen: "Ve verde y ahorra verde con energía solar",
        contactUs: "CONTACTANOS",
        ourServices: "NUESTROS SERVICIOS"
      },
      about: {
        aboutUsTitle: "Sobre nosotros",
        aboutUsText1: "Fundada por Sebastien, quien se enamoró de los impresionantes paisajes de Calp, nuestra misión es empoderar a los locales y expatriados para que adopten un estilo de vida sostenible a través de soluciones de energía solar.",
        aboutUsText2: "¡Imagina reducir tu factura de electricidad en un increíble 85%! Nuestras instalaciones de paneles solares no solo son una opción inteligente, son un cambio de juego para tu bolsillo y el medio ambiente.",
        aboutUsText3: "Durante más de 15 años, hemos estado bien establecidos en la industria de paneles solares en Bélgica, habiendo completado con éxito varias instalaciones. Hoy, llevamos nuestra experiencia a las soleadas costas de Calp, Altea, Moraira, Benissa y más allá.",
        quoteButton: "Solicitar una cotización gratuita"
      },
      services: {
        ourServicesTitle: "Nuestros Servicios",
        solarPanelInstallation: "Instalación de Paneles Solares",
        airConditioningInstallation: "Instalación de Aire Acondicionado",
        poolHeatPumpInstallation: "Instalación de Bomba de Calor para Piscinas",
        heatPumpBoilerInstallation: "Instalación de Caldera de Bomba de Calor",
        electricCarChargingStation: "Estación de Carga para Coches Eléctricos",
        serviceDetailButton: "Nuestros servicios en detalle",
        freeQuoteButton: "Solicitar una cotización gratuita",
        friendlyPersonalService: "Servicio Amigable y Personalizado",
        installationExperience: "Gran Experiencia en Instalación y Mantenimiento",
        available: "Disponible las 24 horas, los 7 días de la semana"
      },
      ourmission: {
        title: "Nuestra misión",
        paragraph1: "En Eco Green Projects, nos dedicamos a transformar la forma en que piensa sobre la energía. Con una misión centrada en ahorrar dinero a través de la energía solar mientras promovemos la ecología, nos esforzamos por hacer que la vida sostenible sea accesible para todos. Nuestros valores fundamentales de innovación, soluciones inteligentes y sostenibilidad nos guían en cada proyecto que emprendemos.",
        paragraph2: "Con más de 15 años de experiencia en la industria de la energía solar y muchas instalaciones exitosas, nos hemos establecido como un líder de confianza en el campo. Nuestra pasión por la energía renovable está arraigada en nuestro amor por Calp, una localidad bendecida con 300 días de sol al año, lo que la convierte en el lugar perfecto para instalar paneles solares.",
        paragraph3: "Ofrecemos una gama de servicios, incluyendo instalaciones sólidas y la finalización puntual de proyectos, todos diseñados para ayudarle a ahorrar dinero mientras disfruta de los beneficios de la energía limpia. Nuestro enfoque centrado en el cliente está dirigido tanto a expatriados como a locales que poseen viviendas en el sur de España, garantizando que cumplimos con las necesidades únicas de nuestra diversa clientela.",
        viewProjects: "Ver nuestros proyectos",
        askQuote: "Solicitar una cotización gratuita"
      },
      choose: {
        title: "Por qué elegirnos",
        description: "Elegirnos significa que obtendrás un profesional confiable y experimentado con la experiencia necesaria para ofrecer servicios de calidad.",
        experienced_team: {
          name: "Equipo experimentado y capacitado",
          summary: "Nuestro equipo está altamente experimentado y capacitado, listo para manejar cualquier problema que puedas enfrentar"
        },
        reliable_service: {
          name: "Servicio confiable y eficiente",
          summary: "Nos esforzamos por proporcionar un servicio confiable y eficiente a nuestros clientes. Llegamos a tiempo, completamos el trabajo rápidamente y de manera eficiente"
        },
        competitive_pricing: {
          name: "Precios competitivos",
          summary: "Ofrecemos precios competitivos sin comprometer la calidad de nuestro trabajo"
        },
        customer_satisfaction: {
          name: "Satisfacción del cliente",
          summary: "Escuchamos tus necesidades y preocupaciones, y trabajamos contigo para encontrar la mejor solución"
        },
        fast_delivery: {
          name: "Entrega rápida",
          summary: "Somos rápidos en entregar presupuestos e instalaciones"
        }
      },
      navbar: {
        home: "INICIO",
        about: "SOBRE NOSOTROS",
        services: "SERVICIOS",
        projects: "PROYECTOS",
        faqs: "PREGUNTAS FRECUENTES",
        contact: "CONTACTO",
        ask_for_quote: "Solicitar una cotización gratuita"
      },
      footer: {
        description: "Eco Green Projects es tu socio y experto en energía solar para tu proyecto en el sur de España. Nuestra misión es ayudarte a ahorrar dinero gracias a nuestra instalación.",
        our_services: "Nuestros Servicios",
        solar_panel_installation: "Instalación de paneles solares",
        air_conditioning_installation: "Soluciones de instalación de aire acondicionado",
        pool_heat_pump_installation: "Instalación de bomba de calor para piscina",
        electric_car_charging_station_installation: "Instalación de estaciones de carga para coches eléctricos",
        contact: "Contacto",
        follow_us: "Síguenos",
        our_presence: "Nuestra Presencia",
        solar_installation_in_calpe: "Instalación solar en Calpe",
        terms_conditions: "Términos y Condiciones",
        privacy_policy: "Política de Privacidad"
      },
      aboutpage: {
        title: "Sobre nosotros",
        heading: "En Eco Green Project, estamos comprometidos a transformar la manera en que aprovechas la energía.",
        founder_info: "Fundado por Sebastien, quien se enamoró de los impresionantes paisajes de Calp, nuestra misión es empoderar a los lugareños y expatriados para adoptar un estilo de vida sostenible a través de soluciones de energía solar.",
        savings: "¡Imagina reducir tu factura de electricidad en un increíble 85%! Nuestras instalaciones de paneles solares no solo son una opción inteligente, ¡son un cambio total para tu bolsillo y el medio ambiente!",
        experience: "Durante más de 15 años, hemos estado bien establecidos en la industria de paneles solares en Bélgica, habiendo completado con éxito varias instalaciones. Hoy, llevamos nuestra experiencia a las soleadas costas de Calp, Altea, Moraira, Benissa y más allá.",
        services_title: "Nuestros servicios incluyen:",
        services: [
          "Instalación de paneles solares: maximiza tus ahorros energéticos con nuestra tecnología solar de última generación.",
          "Soluciones de aire acondicionado: mantén tu hogar fresco y cómodo durante los calurosos meses de verano.",
          "Bombas de calor para piscinas: disfruta de tu piscina todo el año con soluciones de calefacción eficientes en energía.",
          "Tanques de agua caliente termodinámicos: aprovecha la energía renovable para tus necesidades de agua caliente.",
          "Terminales eléctricos: asegúrate de que tu hogar esté equipado con las últimas soluciones eléctricas."
        ],
        closing: "¡Únete a nosotros en nuestro compromiso de crear un ambiente ecológico mientras ahorras dinero! Deja que Eco Green Project te guíe en tu camino hacia un estilo de vida sostenible. ¡Contáctanos hoy mismo para descubrir cómo podemos ayudarte a cambiar a la energía solar y disfrutar de los beneficios de un estilo de vida más verde!"
      },
      result: {
        title: "Nuestros Servicios",
        services: [
          {
            title: "Instalación de paneles solares",
            description: "<span style=display:block;><strong>En Eco Green Projects, ofrecemos servicios profesionales de instalación de paneles solares para ayudarle a aprovechar la energía renovable y reducir sus facturas de electricidad.</strong></span></br><span style=display:block;> Nuestros técnicos certificados realizan una instalación experta, utilizando paneles solares de alta calidad para garantizar la máxima eficiencia y la mínima interrupción en su rutina diaria.</span></br><span style=display:block;> Únase a la revolución de la energía verde y haga el cambio a una energía sostenible y rentable hoy mismo.</span>",
            button: "Solicitar una cotización gratuita",
            altText: "Instalación de paneles solares"
          },
          {
            title: "Soluciones de instalación de aire acondicionado",
            description: "<span style=display:block;><strong>En Eco Green Projects, proporcionamos servicios profesionales de instalación de aire acondicionado para asegurar el confort óptimo de su hogar o negocio.</strong></span></br><span style=display:block;> Nuestro equipo certificado de HVAC utiliza unidades de alta calidad y la última tecnología para ofrecer soluciones de refrigeración eficientes y que ahorran energía, adaptadas a sus necesidades.</span></br><span style=display:block;> Mejore su entorno interior y disfrute de un clima perfectamente controlado todo el año.</span>",
            button: "Solicitar una cotización gratuita",
            altText: "Instalación de aire acondicionado"
          },
          {
            title: "Instalación de bombas de calor para piscina",
            description: "<span style=display:block;><strong>En Eco Green Projects, ofrecemos instalación profesional de bombas de calor para piscina para ayudarle a disfrutar de una experiencia de natación cómoda durante todo el año.</strong></span></br><span style=display:block;> Nuestras bombas de calor energéticamente eficientes están adaptadas al tamaño y las necesidades de su piscina, garantizando un rendimiento óptimo y ahorro de costos.</span></br><span style=display:block;> Mejore su piscina con soluciones de calefacción ecológicas que reduzcan los costos de energía y extiendan la temporada de natación.</span>",
            button: "Solicitar una cotización gratuita",
            altText: "Instalación de bombas de calor para piscina"
          },
          {
            title: "Instalación de calderas de bomba de calor",
            description: "<span style=display:block;><strong>En Eco Green Projects, proporcionamos instalaciones eficientes y sostenibles de calderas de bomba de calor para hogares y empresas.</strong></span></br><span style=display:block;> Nuestros profesionales certificados utilizan tecnología de bomba de calor de última generación para reducir los costos de energía y minimizar su huella de carbono.</span></br><span style=display:block;> Experimente una instalación sin problemas y adopte calefacción eficiente en energía que apoya un futuro más verde.</span>",
            button: "Solicitar una cotización gratuita",
            altText: "Instalación de calderas de bomba de calor"
          },
          {
            title: "Instalación de estaciones de carga para coches eléctricos",
            description: "<span style=display:block;><strong>En Eco Green Projects, nos especializamos en la instalación de estaciones de carga para vehículos eléctricos (VE) para apoyar el transporte sostenible.</strong></span></br><span style=display:block;> Nuestro equipo experto proporciona instalaciones eficientes y confiables con equipos de alta calidad, garantizando un rendimiento óptimo y la mínima interrupción en su rutina diaria.</span></br><span style=display:block;> Actualice a un futuro más verde con nuestras soluciones personalizadas de carga de VE para su hogar o negocio.</span>",
            button: "Solicitar una cotización gratuita",
            altText: "Instalación de estaciones de carga para coches eléctricos"
          }
        ]
      },      
      project: {
        title: "Nuestros Proyectos",
        projectTitle: "NUESTRO PROYECTO EN CALPE",
        description1: "Nos complace presentar nuestro proyecto inaugural en Calpe.",
        description2: "Este proyecto incluye una instalación solar completa equipada con un sistema de baterías. Además, hemos implementado aire acondicionado, una bomba de calor y una estación de carga para vehículos eléctricos para mejorar la sostenibilidad y la eficiencia de la propiedad.",
        energyGraph: "Gráfico de Consumo de Energía",
        installationOverview: "Resumen de la Instalación"
      },
     "faqTitle": "FAQ: Servicios de energía solar e instalación en el sur de España",
  "faq1": {
    "title": "¿Cuánto cuesta una instalación de paneles solares en el sur de España?",
    "content": "El costo de una instalación de paneles solares en el sur de España puede variar dependiendo de factores como el tamaño de su casa, la capacidad del sistema y las necesidades energéticas. Para los propietarios en áreas como Calp, Altea y Benissa, los precios generalmente oscilan entre 5.000 € y 12.000 € para una instalación completa. Los expatriados pueden beneficiarse de ahorros significativos en sus facturas de energía a largo plazo. Para obtener un precio preciso adaptado a su hogar, ofrecemos presupuestos gratuitos y sin compromiso."
  },
  "faq2": {
    "title": "¿Cuál es el ROI (Retorno de Inversión) de los paneles solares en España?",
    "content": "El ROI para los paneles solares en España es excelente, especialmente a lo largo de la Costa Blanca, donde hay abundante sol. La mayoría de los propietarios obtienen un retorno de inversión en 4 a 5 años, dependiendo del tamaño del sistema y el consumo energético. Para los expatriados en áreas como Moraira y Benissa, los ahorros pueden ser aún mayores debido a la reducción de la dependencia de fuentes de energía tradicionales. La energía solar puede reducir sus facturas de electricidad hasta en un 70 %, lo que la convierte en una inversión inteligente a largo plazo."
  },
  "faq3": {
    "title": "¿Por qué debería elegir su empresa para la instalación de paneles solares?",
    "content": "Con 20 años de experiencia en Bélgica, hemos construido una reputación de calidad y fiabilidad. Traemos el mismo nivel de experiencia al sur de España, sirviendo a áreas como Calp, Altea y más allá. Nuestras ventajas clave incluyen:<ul><li>Respuestas rápidas: Proporcionamos presupuestos rápidamente, por lo que no tiene que esperar.</li><li>Instalación eficiente: Realizamos instalaciones más rápido que la mayoría de nuestros competidores, asegurando una mínima interrupción.</li><li>Soporte multilingüe: Nuestro equipo habla inglés, español, francés y neerlandés, facilitando la comunicación con los expatriados.</li></ul>Somos su socio confiable para soluciones de energía solar en la Costa Blanca."
  },
  "faq4": {
    "title": "¿Cuánto tiempo lleva instalar paneles solares?",
    "content": "Nos enorgullecemos de realizar instalaciones de paneles solares de manera rápida. Generalmente, el proceso toma entre 1 y 3 días, dependiendo del tamaño del sistema y la complejidad de la instalación. Una vez que reciba su presupuesto y decida continuar, generalmente podemos programar la instalación dentro de una semana para propiedades en Calp, Altea, Moraira, Benissa y áreas cercanas. Nuestro objetivo es hacer que el proceso sea lo más rápido y sencillo posible."
  },
  "faq5": {
    "title": "¿Cómo obtengo un presupuesto para la instalación de paneles solares?",
    "content": "Obtener un presupuesto gratuito para paneles solares es fácil. Puede:<ul><li>Llamarnos o enviarnos un correo electrónico, y nuestro equipo organizará una consulta.</li><li>Completar el formulario en nuestro sitio web, y responderemos en un plazo de 24 horas.</li></ul>Ofrecemos presupuestos personalizados basados en sus necesidades energéticas y las especificaciones de su propiedad, asegurando que obtenga el precio más preciso y competitivo disponible en el sur de España."
  },
  "faq6": {
    "title": "¿Qué tipos de instalaciones ofrecen?",
    "content": "Nos especializamos en una variedad de soluciones energéticas, que incluyen:<ul><li>Instalación de paneles solares: sistemas eficientes y rentables adaptados a su hogar.</li><li>Instalación de aire acondicionado: unidades energéticamente eficientes que mantienen su hogar cómodo durante todo el año.</li><li>Instalación de bombas de calor para piscinas: extienda su temporada de baño con un sistema de calefacción ecológico.</li><li>Instalación de calderas de bomba de calor: calefacción sostenible para el suministro de agua de su hogar.</li><li>Instalación de estaciones de carga para vehículos eléctricos: soluciones convenientes para cargar su vehículo eléctrico en casa.</li></ul>Cada servicio está disponible en Calp, Altea, Moraira, Benissa y las áreas circundantes."
  },
  "faq7": {
    "title": "¿Qué áreas sirven en el sur de España?",
    "content": "Servimos con orgullo a propietarios y expatriados a lo largo de toda la Costa Blanca, incluyendo:<ul><li>Calp</li><li>Altea</li><li>Moraira</li><li>Benissa</li></ul>Ya sea que esté buscando soluciones de energía solar, instalaciones de aire acondicionado o estaciones de carga para vehículos eléctricos, ofrecemos servicios de alta calidad y confiables en toda la región."
  },
  contactButtonText: "¡Tu presupuesto en pocos clics!"

    }
  }
};
