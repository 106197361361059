import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaGlobe } from 'react-icons/fa';
import PropTypes from 'prop-types';
import './LanguageSwitcher.css';

// Add flag library
import Flag from 'react-world-flags';

const LanguageSwitcher = ({ onLanguageChange }) => {
  const { i18n } = useTranslation(); // Make sure i18n is initialized here
  const [showOptions, setShowOptions] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Ensure this function is available
    onLanguageChange(lng);
    setShowOptions(false); // Close the dropdown after selecting a language
  };

  return (
    <div className={`language-switcher ${showOptions ? 'open' : ''}`}>
      <button onClick={() => setShowOptions(!showOptions)} className="language-button">
        <FaGlobe className="globe-icon" />
      </button>
      <div className="language-options">
        <button className="language-option" onClick={() => changeLanguage('en')}>
          <Flag code="GB" className="flag-icon" /> English
        </button>
        <button className="language-option" onClick={() => changeLanguage('fr')}>
          <Flag code="FR" className="flag-icon" /> Français
        </button>
        <button className="language-option" onClick={() => changeLanguage('nr')}>
          <Flag code="NL" className="flag-icon" /> Nederlands
        </button>
        <button className="language-option" onClick={() => changeLanguage('al')}>
          <Flag code="DE" className="flag-icon" /> Deutsch
        </button>
        <button className="language-option" onClick={() => changeLanguage('es')}>
          <Flag code="ES" className="flag-icon" /> Español
        </button>
      </div>
    </div>
  );
};

LanguageSwitcher.propTypes = {
  onLanguageChange: PropTypes.func.isRequired,
};

export default LanguageSwitcher;
