import React from "react";
import Faq from "react-faq-component";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useTranslation } from 'react-i18next';

const ArrowIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 12H18M12 6V18" stroke="#5b8251" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const Faqs = () => {
  const { t } = useTranslation();

  const data = {
    rows: [
      {
        title: t('faq1.title'),
        content: t('faq1.content'),
      },
      {
        title: t('faq2.title'),
        content: t('faq2.content'),
      },
      {
        title: t('faq3.title'),
        content: t('faq3.content'),
      },
      {
        title: t('faq4.title'),
        content: t('faq4.content'),
      },
      {
        title: t('faq5.title'),
        content: t('faq5.content'),
      },
      {
        title: t('faq6.title'),
        content: t('faq6.content'),
      },
      {
        title: t('faq7.title'),
        content: t('faq7.content'),
      },
    ],
  };

  const styles = {
    bgColor: "#ffffff", 
    titleTextColor: "#5b8251", 
    rowTitleColor: "black", 
    rowContentColor: "gray", 
    arrowColor: "#5b8251", 
  };

  const config = {
    animate: true,
    arrowIcon: <ArrowIcon />,
  };

  return (
    <div>
      <Navbar />
      <div className="bg-[#ffffff] py-10 px-5 md:px-10 text-black flex flex-col space-y-4">
        <div className="text-center mb-14">
          <h1 style={{ color: "#5b8251", textAlign: "center", lineHeight: "1.5", fontSize: "2rem", fontWeight: "bold" }}>
            {t('faqTitle')}
            <span className="block relative w-32 h-2 bg-[#5b8251] left-1/2 transform -translate-x-1/2 -translate-y-0 rounded-md"></span>
          </h1>
        </div>

        <div className="faqs">
          <Faq config={config} styles={styles} data={data} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Faqs;
